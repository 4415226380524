import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Stripe from 'Containers/StripePayment';
import { toast } from 'react-hot-toast';
import {
  getPaymentDetails,
  getCashPatientAmount,
} from 'Redux/Checkin/newCheckin.actions';
import Loader from 'components/Loader';
import PayOffice from './payOffice';
import './style.css';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

function PaymentDetails() {
  const [loading, setLoading] = useState(true);
  const [skipPayment, setSkipPayment] = useState(false);
  const [paymentOption, setPaymentOption] = useState(null);
  const [appointment, setAppointment] = useState({});
  const [visitId, setVisitId] = useState([]);
  const [paymentScreen, setPaymentScreen] = useState(false);
  const [balance, setBalance] = useState(0);
  const [estimate, setEstimate] = useState(-1);
  const [copay, setCopay] = useState(null);
  const [copayOrCoins, setCopayOrCoins] = useState('Coinsurance');
  const [deductible, setDeductible] = useState(null);
  const [allowed, setAllowed] = useState(null);
  const status = 1;
  const [statusMessage, setStatusMessage] = useState('');
  const [cashPatient, setCashPatient] = useState(0);
  const [cashEstimate, setCashEstimate] = useState(-1);
  const pin = localStorage.getItem('pin');
  const patientId = localStorage.getItem('patientId');
  const switchToCash = parseInt(localStorage.getItem('switchToCash'));
  const primaryInsuranceEdited = parseInt(
    localStorage.getItem('primaryInsuranceEdited')
  );
  const dispatch = useDispatch();
  const { cashPatientAmount, paymentDetails } = useSelector(
    (state) => state.checkin
  );
  const apmtId = localStorage.getItem('apmtId');
  function getDate(date) {
    if (date === null) {
      return null;
    }
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }
    if (Number.isNaN(year)) {
      return null;
    }
    return [month, day, year].join('/');
  }

  function getTime(time) {
    let t = new Date(time);
    let h = t.getHours();
    let ampm;
    if (h < 12) {
      ampm = 'AM';
    } else ampm = 'PM';
    if (h > 12) {
      h = Math.abs(12 - h);
    }
    let hours;
    if (h < 10 && h > 0) {
      hours = '0' + h.toString();
    } else if (h === 0) {
      hours = '12';
    } else hours = h.toString();
    let mins = t.getMinutes();
    let minutes;
    if (mins < 10) {
      minutes = '0' + mins.toString();
    } else minutes = mins.toString();

    return hours + ':' + minutes + ' ' + ampm;
  }

  const handlePay = () => {
    setPaymentScreen(true);
  };

  const handlePrev = () => {
    setPaymentScreen(false);
    setSkipPayment(false);
  };

  const handleSkip = () => {
    setSkipPayment(true);
  };

  const handleBackButton = () => {
    window.location.href = '/insurance';
  };

  const handleChangePayment = (e) => {
    setPaymentOption(e.target.value);
  };

  const handleCheckin = () => {
    window.location.href = '/appointment';
  };

  // console.log(switchToCash);
  useEffect(() => {
    let a = localStorage.getItem('apmtDetails');
    setAppointment(JSON.parse(a));
    let id = parseInt(localStorage.getItem('apmtId'));
    let typeId = parseInt(localStorage.getItem('apmtTypeId'));
    if (switchToCash === 1) {
      setCashPatient(true);
      dispatch(
        getCashPatientAmount({
          typeId: typeId,
          clinicId: parseInt(localStorage.getItem('clinicId')),
          payerId: 0,
          patientId: parseInt(patientId),
          appointmentId: parseInt(apmtId),
        })
      );
    } else {
      dispatch(getPaymentDetails(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cashPatientAmount && cashPatientAmount.data) {
      if (cashPatientAmount.responseCode === 0) {
        let data = cashPatientAmount.data;
        if (data.allowed) {
          setCashEstimate(
            data.allowed >= 0 ? parseFloat(data.allowed).toFixed(2) : -1
          );
        }
        setBalance(
          data.currentBalance ? parseFloat(data.currentBalance).toFixed(2) : 0
        );
        setVisitId(data?.visitId.length ? data.visitId : []);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashPatientAmount.data]);

  useEffect(() => {
    if (paymentDetails && paymentDetails.data) {
      if (paymentDetails.responseCode === 0) {
        let data = paymentDetails.data;
        let bal = data.currentBalance && data.currentBalance;

        setVisitId(data.visitId.length ? data.visitId : []);
        if (data.applicableCoins && parseInt(data.applicableCoins) !== 0) {
          setCopay(
            data.applicableCoins &&
              parseInt(parseFloat(data.applicableCoins).toFixed(2))
          );
          setCopayOrCoins('Coinsurance');
        } else if (
          parseInt(data.applicableCoins) === 0 &&
          parseInt(data.copay) === 0
        ) {
          setCopay(
            data.applicableCoins &&
              parseInt(parseFloat(data.applicableCoins).toFixed(2))
          );
          setCopayOrCoins('Coinsurance');
        } else {
          setCopay(data.copay && parseFloat(data.copay).toFixed(2));
          setCopayOrCoins('Copay');
        }
        setDeductible(
          data.applicableDeductible &&
            parseFloat(data.applicableDeductible).toFixed(2)
        );
        setBalance(bal && parseFloat(bal).toFixed(2));
        setAllowed(data.allowed && parseFloat(data.allowed).toFixed(2));

        if (!primaryInsuranceEdited) {
          setEstimate(
            data.estimate >= 0 ? parseFloat(data.estimate).toFixed(2) : -1
          );
        }

        if (bal + data.estimate <= 0) {
          window.location.href = '/appointment';
        }
        // else if (data.estimate < 0) {
        //   window.location.href = '/appointment';
        // }
        else {
          setLoading(false);
        }
        // setLoading(false)

        if (data.status === 2) {
          setStatusMessage(
            'Your estimate is based on Medicare rates; Your insurance allowed might vary'
          );
        } else if (data.status === 4) {
          setStatusMessage(
            'Sorry, we are missing your copay or deductible information and hence not able to estimate your visit'
          );
        } else if (data.status === 3) {
          setStatusMessage(
            'Sorry, we are missing your copay or deductible information and hence not able to estimate your visit'
          );
        } else if (data.status === 5) {
          setStatusMessage('Sorry, we are unable to estimate your visit');
        } else {
          setStatusMessage('');
        }
      } else if (paymentDetails.responseCode === 113) {
        toast.error('Session expired. Please Login again');
        window.location.href = '/' + pin;
      } else if (paymentDetails.responseCode === 131) {
        // Data not found
        window.location.href = '/appointment';
      } else {
        // toast.error("Unknown error. Please login again");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDetails.data]);

  let render = (
    <>
      {paymentScreen ? (
        <Stripe
          amount={
            paymentOption === '1'
              ? parseFloat(balance)
              : paymentOption === '2' && !cashPatient
              ? parseFloat(estimate)
              : paymentOption === '2' && cashPatient
              ? parseFloat(cashEstimate)
              : paymentOption === '3' && !cashPatient
              ? parseFloat(balance) + parseFloat(estimate)
              : paymentOption === '3' && cashPatient
              ? parseFloat(balance) + parseFloat(cashEstimate)
              : ''
          }
          paymentOption={
            paymentOption === '1'
              ? 0
              : paymentOption === '2' && !cashPatient
              ? 1
              : paymentOption === '2' && cashPatient
              ? 3
              : paymentOption === '3' && !cashPatient
              ? 2
              : paymentOption === '3' && cashPatient
              ? 3
              : 0
          }
          visitIdOption={parseInt(paymentOption)}
          handlePrev={handlePrev}
          visitId={visitId.length ? visitId : []}
        />
      ) : (
        <Grid
          container
          className="payment_details_container"
        >
          <Grid
            xs={12}
            item
            className="apmt_details_container"
          >
            <div className="apmt_details">
              <div className="apmt_details_clinicName">
                {appointment.clinicName}
              </div>
              <div className="apmt_details_line2">
                {appointment.appointmentTypeName +
                  ' - ' +
                  appointment.providerName +
                  ', ' +
                  appointment.credentials}
              </div>
              <div className="apmt_details_line3">
                {getDate(appointment.startTime) +
                  ' ' +
                  getTime(appointment.startTime)}
              </div>
            </div>
          </Grid>
          {!skipPayment ? (
            <>
              <div className="box_subheading">
                {balance >= 0 ? (
                  <div style={{ padding: '12px 0' }}>
                    Old Balance: <span className="box_amount">${balance}</span>
                  </div>
                ) : (
                  ''
                )}
              </div>

              {primaryInsuranceEdited && cashEstimate < 0 ? (
                <div className="estimate_unknown_box">
                  <div className="estimate_unknown_text">
                    We are unable to estimate your responsibility for the
                    current visit; please pay at the time of the visit
                  </div>
                </div>
              ) : cashEstimate < 0 && estimate < 0 ? (
                <div className="estimate_unknown_box">
                  <div className="estimate_unknown_text">
                    We are unable to estimate your responsibility for the
                    current visit; please pay at the time of the visit
                  </div>
                </div>
              ) : (
                <>
                  <Grid
                    xs={12}
                    item
                    className="details_heading"
                  >
                    <div className="details_heading_text">
                      Estimate of your responsibility at the time of your visit:
                    </div>
                  </Grid>

                  <Grid
                    xs={12}
                    item
                  >
                    <div className="payment_details_box">
                      <Grid
                        container
                        className="payment_details_box_content"
                      >
                        <Grid
                          item
                          xs={12}
                          className="box_subheading"
                        >
                          <div className="payment_details_row">
                            Visit Type :{' '}
                            <span className="box_amount">
                              {appointment.appointmentTypeName}
                            </span>
                          </div>
                        </Grid>

                        {cashPatient ? (
                          ''
                        ) : (
                          <Grid
                            item
                            xs={12}
                            className="box_subheading"
                          >
                            <div className="payment_details_row">
                              {copayOrCoins} :{' '}
                              {!primaryInsuranceEdited && copay >= 0 ? (
                                <span className="box_amount">
                                  {copayOrCoins === 'Copay' && '$'}
                                  {copay ? copay : '0'}
                                  {copayOrCoins === 'Coinsurance' && '%'}
                                </span>
                              ) : (
                                <span className="box_amount">Unknown</span>
                              )}
                            </div>
                          </Grid>
                        )}

                        {cashPatient ? (
                          ''
                        ) : (
                          <Grid
                            item
                            xs={12}
                            className="box_subheading"
                          >
                            <div className="payment_details_row">
                              Deductible <sup className="superscript">1</sup> :{' '}
                              <span className="box_amount">
                                {!primaryInsuranceEdited && deductible >= 0
                                  ? '$' + deductible
                                  : 'Unknown'}
                              </span>
                            </div>
                          </Grid>
                        )}

                        {cashPatient ? (
                          ''
                        ) : (
                          <Grid
                            item
                            xs={12}
                            className="box_subheading"
                          >
                            <div className="payment_details_row">
                              Allowed <sup className="superscript">2</sup> :{' '}
                              <span className="box_amount">
                                {primaryInsuranceEdited
                                  ? 'Unknown'
                                  : primaryInsuranceEdited && allowed === 0
                                  ? '$0'
                                  : allowed
                                  ? '$' + allowed
                                  : allowed === 0
                                  ? '$0'
                                  : 'Unkown'}
                              </span>
                            </div>
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={12}
                          className="box_subheading"
                        >
                          <div className="payment_details_row">
                            Estimate{' '}
                            <sup className="superscript">
                              {cashPatient ? '1' : '3'}
                            </sup>{' '}
                            :{' '}
                            <span className="box_amount">
                              {estimate >= 0 &&
                              !cashPatient &&
                              !primaryInsuranceEdited
                                ? '$' + estimate
                                : cashEstimate >= 0 && cashPatient
                                ? '$' + cashEstimate
                                : 'Unknown'}
                              <sup className="superscript">
                                {status !== 1 && !cashPatient ? '4' : ''}
                              </sup>
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </>
              )}

              {balance > 0 || estimate > 0 || cashEstimate > 0 ? (
                <Grid
                  xs={12}
                  item
                  className="payment_options_container"
                >
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="options"
                      name="options"
                      value={paymentOption}
                      onChange={handleChangePayment}
                    >
                      {balance > 0 ? (
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label={'Pay Previous Balance: $' + balance}
                        />
                      ) : (
                        ''
                      )}
                      {estimate > 0 ? (
                        <FormControlLabel
                          value="2"
                          control={<Radio color="primary" />}
                          label={'Pay Estimate for current visit: $' + estimate}
                        />
                      ) : (
                        ''
                      )}
                      {cashEstimate > 0 ? (
                        <FormControlLabel
                          value="2"
                          control={<Radio color="primary" />}
                          label={
                            'Pay Estimate for current visit: $' + cashEstimate
                          }
                        />
                      ) : (
                        ''
                      )}
                      {balance > 0 && estimate > 0 ? (
                        <FormControlLabel
                          value="3"
                          control={<Radio color="primary" />}
                          label={
                            'Pay Balance + Estimate: $' +
                            (
                              parseFloat(balance) + parseFloat(estimate)
                            ).toFixed(2)
                          }
                        />
                      ) : balance === 0 && estimate > 0 ? (
                        ''
                      ) : (
                        ''
                      )}
                      {balance > 0 && cashEstimate > 0 ? (
                        <FormControlLabel
                          value="3"
                          control={<Radio color="primary" />}
                          label={
                            'Pay Balance + Estimate: $' +
                            (
                              parseFloat(balance) + parseFloat(cashEstimate)
                            ).toFixed(2)
                          }
                        />
                      ) : balance === 0 && cashEstimate > 0 ? (
                        ''
                      ) : (
                        ''
                      )}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              ) : (
                ''
              )}
              {balance > 0 || estimate > 0 || cashEstimate > 0 ? (
                <>
                  <Grid
                    xs={12}
                    item
                    className="pay_card_button_container"
                  >
                    <Button
                      className="pay_card_button"
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={handlePay}
                      disabled={!paymentOption}
                    >
                      Pay with card
                    </Button>
                  </Grid>
                  <Grid
                    xs={12}
                    item
                    className="pay_office_button_container"
                  >
                    <Button
                      className="pay_office_button"
                      variant="outlined"
                      color="primary"
                      size="medium"
                      onClick={handleSkip}
                      disabled={!paymentOption}
                    >
                      Pay at the office
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid
                  xs={12}
                  item
                  className="complete_checkin_button_container"
                >
                  <Button
                    className="complete_checkin_button"
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => handleCheckin()}
                  >
                    Complete check in
                  </Button>
                </Grid>
              )}
              <Grid
                xs={12}
                item
                className="pay_office_button_container"
              >
                <Button
                  className="pay_office_button"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={handleBackButton}
                >
                  Go Back
                </Button>
              </Grid>
              {primaryInsuranceEdited || !estimate ? (
                ''
              ) : (
                <Grid
                  xs={12}
                  item
                  className="explanations_container"
                >
                  {cashPatient ? (
                    ''
                  ) : (
                    <div className="explanation_text">
                      1 Outstanding deductible
                    </div>
                  )}
                  {cashPatient ? (
                    ''
                  ) : (
                    <div className="explanation_text">
                      {allowed === 0
                        ? '2 We are not able to estimate your allowed amount at this time. You will be given an estimate when you checkin at the frontdesk'
                        : '2 Estimate of what your insurance will allow'}
                    </div>
                  )}
                  {cashPatient ? (
                    <div className="explanation_text">
                      1 - Estimate of what you owe for this visit
                    </div>
                  ) : (
                    <div className="explanation_text">
                      {primaryInsuranceEdited
                        ? '3 Due to the updated insurance information, we are unable to give an estimate at this time'
                        : '3 Estimate of your out of pocket expense for this visit. This is a Good Faith Estimate (GFE) based on our understanding of what you will owe after insurance pays their portion. If insurance denies this claim, you could be liable for the total amount.'}
                    </div>
                  )}
                  {status !== 1 && !cashPatient ? (
                    <div className="explanation_text">{statusMessage}</div>
                  ) : (
                    ''
                  )}
                </Grid>
              )}
            </>
          ) : (
            <PayOffice handlePrev={handlePrev} />
          )}
        </Grid>
      )}
    </>
  );

  return loading ? <Loader /> : render;
}

export default PaymentDetails;
