import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import Input from 'components/Input';
import PhoneFormat from 'components/PhoneFormat';
import { states } from 'HelperFunctions/states';
import { savePersonalDetails } from 'Redux/Checkin/newCheckin.actions';
import { getDetails } from 'Redux/Checkin/newCheckin.actions';
import './style.css';
import { toast } from 'react-hot-toast';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

function EditDetails(props) {
  let pin = localStorage.getItem('pin');
  const dispatch = useDispatch();
  const [phoneError, setPhoneError] = useState(false);
  const [selectedState, setSelectedState] = useState({
    name: '',
    value: '',
  });
  const { detailsResponse } = useSelector((state) => state.checkin);
  const { savePersonalRes } = useSelector((state) => state.checkin);

  const [values, setValues] = useState({
    id: detailsResponse.id,
    clinicId: detailsResponse.clinicId,
    firstName: '',
    lastName: '',
    addressLine1: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: '',
    policyType: 1,
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    addressLine1: '',
    city: '',
    zip: '',
  });

  const phoneValidate = (value) => {
    if (!/^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/.test(value)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  };

  const handleCancel = () => {
    props.handleCancel();
  };

  const handleConfirm = () => {
    if (
      errors.firstName === '' &&
      errors.lastName === '' &&
      errors.addressLine1 === '' &&
      errors.city === '' &&
      errors.zip === '' &&
      !phoneError
    ) {
      if (
        values.firstName &&
        values.lastName &&
        values.addressLine1 &&
        values.city &&
        values.zip &&
        values.state &&
        values.phone
      ) {
        dispatch(
          savePersonalDetails({ ...values, state: selectedState.value })
        );
      } else {
        toast.error('Enter required fields');
      }
    } else {
      console.log('error');
    }
  };

  useEffect(() => {
    if (pin) {
      dispatch(getDetails(pin));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  useEffect(() => {
    if (detailsResponse && detailsResponse.data) {
      if (detailsResponse.responseCode === 0) {
        let data = detailsResponse.data;
        setValues({
          ...values,
          id: data.patientDto.id,
          clinicId: data.appointmentDto.clinicId,
        });
      } else if (
        props.checkinData.paymentDetails.response.responseCode === 113
      ) {
        toast.error('Session expired. Please Login again');
        window.location.href = '/' + pin;
      } else {
        toast.error('Unknown error. Please login again');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsResponse.data]);

  useEffect(() => {
    let pt = JSON.parse(localStorage.getItem('updatedPatient'));
    if (pt) {
      setValues({
        id: pt.id,
        clinicId: pt.clinicId,
        firstName: pt.firstName,
        lastName: pt.lastName,
        addressLine1: pt.addressLine1,
        city: pt.city,
        state: pt.state,
        zip: pt.zip,
        phone: pt.phone,
        email: '',
        policyType: 1,
      });
      let st = states.filter((item) => item.value === pt.state);
      setSelectedState(st[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('updatedPatient')]);

  useEffect(() => {
    if (savePersonalRes && savePersonalRes.data) {
      if (savePersonalRes.responseCode === 0) {
        let data = savePersonalRes.data;
        // props.handleUpdate(data);
        let newValues = {
          id: data.patientId,
          clinicId: data.clinic.clinicId,
          firstName: data.firstName,
          lastName: data.lastName,
          addressLine1: data.addressLine1,
          city: data.city,
          state: data.state,
          zip: data.zip,
          phone: data.phone,
          email: '',
          policyType: 1,
        };
        // console.log(data);
        localStorage.setItem('updatedPatient', JSON.stringify(newValues));
        window.location.href = '/details';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePersonalRes.data]);

  const validateFirstName = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
      setErrors({ ...errors, firstName: 'Enter a valid Name' });
    } else {
      setErrors({ ...errors, firstName: '' });
    }
  };

  const validateLastName = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
      setErrors({ ...errors, lastName: 'Enter a valid Name' });
    } else {
      setErrors({ ...errors, lastName: '' });
    }
  };

  const validateCity = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
      setErrors({ ...errors, city: 'Enter valid City' });
    } else {
      setErrors({ ...errors, city: '' });
    }
  };

  const validateAddress1 = (value) => {
    if (!/^[a-zA-Z-0-9 -'#&@,]+$/.test(value)) {
      setErrors({ ...errors, addressLine1: 'Enter a valid Address' });
    } else {
      setErrors({ ...errors, addressLine1: '' });
    }
  };

  const validateZip = (value) => {
    if (!/^[0-9]{5}(?:-[0-9]{4})?$/.test(value)) {
      setErrors({ ...errors, zip: 'Enter valid Zip' });
    } else {
      setErrors({ ...errors, zip: '' });
    }
  };

  return (
    <>
      <Grid
        xs={12}
        item
        className="edit_details_heading"
      >
        <div className="edit_details_heading_text">
          Enter your address in full
        </div>
      </Grid>
      <Grid
        xs={12}
        item
      >
        <div className="edit_details_box">
          <Grid
            container
            className="edit_details_box_content"
            spacing={2}
          >
            <Grid
              item
              xs={6}
              className="edit_text_box"
            >
              <Input
                type="text"
                required
                label="First name"
                value={values.firstName}
                onChange={(e) =>
                  setValues({ ...values, firstName: e.target.value })
                }
                onBlur={(e) => validateFirstName(e.target.value)}
                error={errors.firstName ? true : false}
                helperText={errors.firstName}
              />
            </Grid>
            <Grid
              item
              xs={6}
              className="edit_text_box"
            >
              <Input
                required
                type="text"
                label="Last name"
                value={values.lastName}
                onChange={(e) =>
                  setValues({ ...values, lastName: e.target.value })
                }
                onBlur={(e) => validateLastName(e.target.value)}
                error={errors.lastName ? true : false}
                helperText={errors.lastName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              className="edit_text_box"
            >
              <Input
                required
                type="text"
                label="Address Line 1"
                value={values.addressLine1}
                onChange={(e) =>
                  setValues({ ...values, addressLine1: e.target.value })
                }
                onBlur={(e) => validateAddress1(e.target.value)}
                error={errors.addressLine1 ? true : false}
                helperText={errors.addressLine1}
              />
            </Grid>
            <Grid
              item
              xs={5}
              className="edit_text_box"
            >
              <Input
                required
                type="text"
                label="City"
                value={values.city}
                onChange={(e) => setValues({ ...values, city: e.target.value })}
                onBlur={(e) => validateCity(e.target.value)}
                error={errors.city ? true : false}
                helperText={errors.city}
              />
            </Grid>
            <Grid
              item
              xs={7}
              className="edit_text_box"
            >
              {/* <Dropdown
                required
                // open={openDropdown}
                // onOpen={() => setOpenDropdown(true)}
                // onClose={handleClose}
                type="text"
                label="State"
                value={values.state}
                options={states}
                onChange={(e) =>
                  setValues({ ...values, state: e.target.value })
                }
                // onKeyPress={(e) => handleStateKeyPress(e)}
                // onClick={() => setOpenDropdown(true)}
              /> */}
              <Autocomplete
                fullWidth
                required
                options={states}
                getOptionLabel={(option) => option.name}
                value={selectedState}
                onChange={(e, v) => {
                  setValues({ ...values, state: v.value });
                  setSelectedState(v);
                }}
                // style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={5}
              className="edit_text_box"
            >
              <Input
                required
                type="text"
                label="Zip"
                value={values.zip}
                onChange={(e) => setValues({ ...values, zip: e.target.value })}
                onBlur={(e) => validateZip(e.target.value)}
                error={errors.zip ? true : false}
                helperText={errors.zip}
              />
            </Grid>
            <Grid
              item
              xs={7}
              className="edit_text_box"
            >
              <PhoneFormat
                required
                type="text"
                label="Phone"
                value={values.phone}
                onChange={(e) =>
                  setValues({ ...values, phone: e.target.value })
                }
                onBlur={(e) => phoneValidate(e.target.value)}
                error={phoneError}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid
        xs={3}
        item
        className="cancel_button_container"
      >
        <Button
          className="cancel_button"
          variant="outlined"
          color="primary"
          size="medium"
          onClick={handleCancel}
        >
          Prev
        </Button>
      </Grid>

      <Grid
        item
        xs={6}
      ></Grid>

      <Grid
        xs={3}
        item
        className="confirm_button_container"
      >
        <Button
          className="confirm_button"
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => handleConfirm()}
        >
          Next
        </Button>
      </Grid>
    </>
  );
}
export default EditDetails;
