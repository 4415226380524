import React from "react";
import { Toaster } from "react-hot-toast";
import TrilliumLogo from "./assets/Trillium-health-logo.png";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./Containers/Login";
import { Typography } from "@material-ui/core";
import PersonalDetails from "Containers/PersonalDetails";
import InsuranceDetails from "Containers/InsuranceDetails";
import PaymentDetails from "Containers/PaymentDetails";
import AppointmentDetails from "Containers/AppointmentDetails";
// import Stripe from "Containers/StripePayment";
// import Success from "Containers/Success";
import "./GlobalStyles.css";

function App() {
  return (
    <div className="app">
      <header className="trillium-health-logo-header">
        <div className="trillium-health-logo-container">
          <a href="https://trillium.health/">
            <img
              src={TrilliumLogo}
              alt="trillium-health-logo"
              className="trillium-health-logo"
            />
          </a>
        </div>
      </header>
      <Toaster position="top-right" reverseOrder={false} />
      <BrowserRouter>
        <React.Suspense fallback={"loading"}>
          <Routes>
            <Route path="/details" element={<PersonalDetails />} />
            <Route path="/insurance" element={<InsuranceDetails />} />
            <Route path="/payment" element={<PaymentDetails />} />
            {/*  /> */}
            {/* <Route path="/success" element={Success} /> */}
            <Route path="/appointment" element={<AppointmentDetails />} />
            <Route path="/:id" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
      <footer className="footer_container">
        <div className="copyright">
          <Typography variant="caption" align="center" color="textSecondary">
            Copyright &copy; {new Date().getFullYear()}-
            {new Date().getFullYear() + 1} trillium.health
          </Typography>
        </div>
      </footer>
    </div>
  );
}

export default App;
