import { Button, Grid } from "@material-ui/core";
import React from "react";
import "./style.css";

function PayOffice(props) {
  const handleCheckin = () => {
    window.location.href = "/appointment";
  };

  const handlePrev = () => {
    props.handlePrev();
  };

  return (
    <>
      <Grid xs={12} item className="details_heading">
        <div className="details_heading_text">
          Please bring your payment to the front desk when you arrive at our
          office.
        </div>
      </Grid>
      <Grid xs={12} item className="pay_card_button_container">
        <Button
          className="pay_card_button"
          variant="contained" 
          color="primary"
          size="large"
          onClick={handleCheckin}
        >
          Complete Check in
        </Button>
      </Grid>
      <Grid xs={12} item className="payOffice_prev_btn_container">
        <Button
          className="payOffice_previous_button"
          variant="outlined"
          color="primary"
          // size="large"
          onClick={handlePrev}
        >
          Go back
        </Button>
      </Grid>
    </>
  );
}

export default PayOffice;
