import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { makePayment } from "Redux/Checkin/newCheckin.actions";
import validator from "validator";

// import { visa, mastercard, amex, union, cvcframe } from '../../assets/images'
import visa from "assets/images/visa.png";
import mastercard from "assets/images/mastercard.png";
import amex from "assets/images/amex.png";
import union from "assets/images/union.png";
import cvcframe from "assets/images/cvcframe.png";

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { countries } from "Utils/country-codes";
import "./style.css";
import { Button } from "@material-ui/core";
import environment from "environments/environment";

const STRIPE_KEY = environment.stripeKey;

const CARD_OPTIONS = {
  // iconStyle: 'solid',
  // style: {
  //   base: {
  //     iconColor: '#c4f0ff',
  //     color: '#272829',
  //     fontWeight: 500,
  //     fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
  //     fontSize: '16px',
  //     fontSmoothing: 'antialiased',
  //     ':-webkit-autofill': {
  //       color: '#fce883',
  //     },
  //     '::placeholder': {
  //       color: '#757575',
  //     },
  //   },
  //   invalid: {
  //     iconColor: '#ff000d',
  //     color: '#ff000d',
  //   },
  // },
};

const CardField = ({ onChange, onFocus, onBlur }) => (
  <div className="FormRow">
    <CardNumberElement
      onFocus={onFocus}
      onBlur={onBlur}
      showIcon={true}
      placeholder={"Card Number"}
      options={CARD_OPTIONS}
      onChange={onChange}
    />
  </div>
);
const CardCvc = ({ onChange, onFocus, onBlur }) => (
  <div className="FormRow">
    <CardCvcElement onBlur={onBlur} onFocus={onFocus} onChange={onChange} />
  </div>
);
const CardExpiry = ({ onChange, onBlur, onFocus }) => (
  <div className="FormRow">
    <CardExpiryElement
      onFocus={onFocus}
      onBlur={onBlur}
      options={CARD_OPTIONS}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, children, disabled, className }) => (
  <button
    color="primary"
    className={className}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const CheckoutForm = (props) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState();
  const [commonMsg, setCommonMessage] = useState(null);
  const [CvcError, setCvcError] = useState(false);
  const [DateError, setDateError] = useState(false);
  const [CardNumberError, setCardNumberError] = useState(false);
  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardCvcComplete, setCardCvcComplete] = useState(false);
  const [cardExpComplete, setCardExpComplete] = useState(false);
  const [btStatus, SetbtStatus] = useState(false);
  const [clinicId, setClinicId] = useState(null);
  const [apmtId, setApmtId] = useState(null);
  const [inputStyle, SetinputStyle] = useState("group");
  const [cardFocus, setFocusCard] = useState(false);
  const [validEmail, SetValidEmail] = useState(null);
  const [validName, SetValidName] = useState(null);
  const [validZip, SetValidZip] = useState(null);
  const [buttonShadow, setButtonShadow] = useState(false);
  const [postalCode, setpostalCode] = useState("");
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: null,
    name: "",
    address: {
      city: null,
      country: "US",
      line1: null,
      line2: null,
      postal_code: null,
      state: null,
    },
  });

  const countryfn = (e) => {
    const bill = billingDetails;
    bill.address.country = e.target.value;
    setBillingDetails(bill);
    if (billingDetails.address.postal_code !== null) {
      validateZip();
    }
  };
  const zipCodefn = (e) => {
    setpostalCode(e.target.value);
    const bill = billingDetails;
    bill.address.postal_code = e.target.value;
    setBillingDetails(bill);
  };

  useEffect(() => {
    let clinicId = parseInt(localStorage.getItem("clinicId"));
    setClinicId(clinicId);
    let apmtId = parseInt(localStorage.getItem("apmtId"));
    setApmtId(apmtId);
  }, []);

  const handlePrev = () => {
    props.handlePrev();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      cardNumberComplete &&
      cardCvcComplete &&
      cardExpComplete &&
      validZip === null
    ) {
      SetbtStatus(true);
      props.changeErStatus(false);

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: billingDetails,
      });

      if (payload.error) {
        SetbtStatus(false);
        setCommonMessage(
          "This card number is not valid. Please enter a valid card number"
        );
        setError(payload.error);
      } else {
        let patientId = localStorage.getItem("patientId");
        dispatch(
          makePayment({
            amount: props.amount * 100,
            currency: "USD",
            clinicId: clinicId,
            appointmentId: apmtId,
            stripeToken: payload.paymentMethod.id,
            patientId: patientId,
            paymentType: "card",
            cardType: 1,
            patientCheckInPayType: props.paymentOption
              ? parseInt(props.paymentOption)
              : 0,
            visitIds:
              props.visitIdOption === 1
                ? props.visitId
                : props.visitIdOption === 2
                ? []
                : props.visitIdOption === 3
                ? props.visitId
                : [],
          })
        );
      }
    }
  };

  const focusCardNumber = () => {
    setFocusCard(true);
  };

  const onBlurCardNumber = () => {
    setFocusCard(false);
    if (cardNumberComplete) {
      setCardNumberError(null);
      SetinputStyle("group");
    } else {
      setCommonMessage(
        "This card number is not valid. Please enter a valid card number"
      );
      setCardNumberError(true);
    }
  };

  const onBlurCvc = () => {
    setFocusCard(false);
    if (cardCvcComplete) {
      setCvcError(null);
      SetinputStyle("group");
    } else {
      setCommonMessage("Invalid CVC. Please enter a valid CVC");
      setCvcError(true);
    }
  };

  const onBlurExp = () => {
    setFocusCard(false);
    if (cardExpComplete) {
      setDateError(null);
      SetinputStyle("group");
    } else {
      setCommonMessage(
        "The card expiration date is not valid. Please enter a valid date"
      );
      setDateError(true);
    }
  };

  const onChangeStripe = (e) => {
    if (
      (e.error && e.error.code === "incomplete_number") ||
      (e.error && e.error.code === "invalid_number")
    ) {
      setCardNumberError(true);
      setCommonMessage(
        "This card number is not valid. Please enter a valid card number"
      );
      setCardNumberComplete(false);
    } else if (
      (e.error && e.error.code === "incomplete_expiry") ||
      (e.error && e.error.code === "invalid_expiry_year_past")
    ) {
      setDateError(true);
      setCommonMessage(
        "The card expiration date is not valid. Please enter a valid date"
      );
      setCardExpComplete(false);
    } else if (e.error && e.error.code === "incomplete_cvc") {
      setCvcError(true);
      setCommonMessage("Invalid CVC. Please enter a valid CVC");
      setCardCvcComplete(false);
    } else if (e.elementType === "cardExpiry" && e.complete === true) {
      setDateError(false);
      setCommonMessage(null);
      setCardExpComplete(true);
      if (CardNumberError) {
        setCommonMessage(
          "This card number is not valid. Please enter a valid card number"
        );
      }
      if (CvcError) {
        setCommonMessage("Invalid CVC. Please enter a valid CVC");
      }
    } else if (e.elementType === "cardCvc" && e.complete === true) {
      setCvcError(false);
      setCommonMessage(null);
      setCardCvcComplete(true);
      if (CardNumberError) {
        setCommonMessage(
          "This card number is not valid. Please enter a valid card number"
        );
      }
      if (DateError) {
        setCommonMessage(
          "The card expiration date is not valid. Please enter a valid date"
        );
      }
    } else if (e.elementType === "cardNumber" && e.complete === true) {
      setCardNumberError(false);
      setCommonMessage(null);
      setCardNumberComplete(true);
      if (DateError) {
        setCommonMessage(
          "The card expiration date is not valid. Please enter a valid date"
        );
      }
      if (CvcError) {
        setCommonMessage("Invalid CVC. Please enter a valid CVC");
      }
    }
  };

  const validateEmail = (e) => {
    const email = e.target.value;
    if (email) {
      const options = { min: 13, max: 35 };
      if (validator.isEmail(email) && validator.isLength(email, options)) {
        SetValidEmail(null);
      } else {
        SetValidEmail(
          "Invalid email address. Please enter a valid email address"
        );
      }
    } else {
      SetValidEmail("Please enter your email address");
    }
  };

  const validateName = (e) => {
    if (e.target.value) {
      if (/^[a-zA-Z ]+$/.test(e.target.value)) {
        SetValidName(null);
      } else {
        SetValidName(
          "Invalid name. The cardholder name should match the name in front of the card"
        );
      }
    } else {
      SetValidName("Please enter the cardholder name");
    }
  };

  const validateZip = (e) => {
    if (billingDetails.address.country === "MX") {
      if (billingDetails.address.postal_code) {
        if (
          /^[0-9]*$/.test(billingDetails.address.postal_code) &&
          billingDetails.address.postal_code.length === 5
        ) {
          SetValidZip(null);
        } else {
          SetValidZip("Invalid zip code. Please enter a valid ZIP code");
        }
      } else {
        SetValidZip("Please enter your ZIP code");
      }
    }
    if (billingDetails.address.country === "US") {
      if (billingDetails.address.postal_code) {
        if (
          /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(billingDetails.address.postal_code)
        ) {
          SetValidZip(null);
        } else {
          SetValidZip("Invalid zip code. Please enter a valid zip code");
        }
      } else {
        SetValidZip("Please enter your zip code");
      }
    }
    if (billingDetails.address.country === "CA") {
      if (billingDetails.address.postal_code) {
        if (
          /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(
            billingDetails.address.postal_code
          ) &&
          billingDetails.address.postal_code.length === 6
        ) {
          SetValidZip(null);
        } else {
          SetValidZip("Invalid zip code. Please enter a valid zip code");
        }
      } else {
        SetValidZip("Please enter your zip code");
      }
    }
  };

  useEffect(() => {
    switch (props.erorrCode) {
      case 508:
        setCvcError(true);
        break;
      case 502:
        SetValidZip(true);
        break;
      default:
        break;
    }
  }, [props.erorrCode]);

  useEffect(() => {
    if (
      cardNumberComplete &&
      cardCvcComplete &&
      cardExpComplete &&
      validEmail === null &&
      validZip === null &&
      validName === null &&
      billingDetails.name &&
      billingDetails.email &&
      billingDetails.address.postal_code
    ) {
      setButtonShadow(true);
    } else {
      setButtonShadow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    billingDetails.email,
    billingDetails.name,
    postalCode,
    cardNumberComplete,
    cardCvcComplete,
    cardExpComplete,
    validEmail,
    validZip,
    validName,
  ]);

  return (
    <div className="StripePaymentSection">
      <div className="payTitle">Pay with card</div>

      <form className="Form" onSubmit={handleSubmit}>
        <div className="fieldWrapper">
          <div className="FieldTitle">Email</div>
          <div className={validEmail ? "errorgroup" : "group"}>
            <div className="fielditem">
              <input
                autoFocus={props.amount ? true : false}
                className="field"
                label="Email"
                id="email"
                type="email"
                required
                autoComplete="off"
                onBlur={validateEmail}
                value={billingDetails.email}
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    email: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          {validEmail && <div className="ErrorMessage">{validEmail}</div>}
        </div>

        <div className="fieldWrapper">
          <div className="FieldTitle">Card Information</div>
          <div className={cardFocus ? "groupfocus" : inputStyle}>
            <div
              id="cardInfoField"
              className={
                props.erorrMsg === 510 || CardNumberError
                  ? "card-error"
                  : "cardfield"
              }
            >
              <CardField
                onFocus={focusCardNumber}
                onBlur={onBlurCardNumber}
                onChange={(e) => onChangeStripe(e)}
              />
              <div className="cardIconSet">
                <img src={visa} alt="card-img" />
                <img src={mastercard} alt="card-img" />
                <img src={amex} alt="card-img" />
                <img src={union} alt="card-img" />
              </div>
            </div>
            <div className="divide-div">
              <div className={DateError ? "date-error" : "expfield"}>
                <CardExpiry
                  onFocus={focusCardNumber}
                  onBlur={onBlurExp}
                  onChange={(e) => onChangeStripe(e)}
                />
              </div>

              <div
                id="cvcInfoField"
                className={
                  props.erorrCode === 508 || CvcError ? "cvc-error" : "cvcfield"
                }
              >
                <CardCvc
                  onBlur={onBlurCvc}
                  onChange={(e) => onChangeStripe(e)}
                />
                <div className="cvcframe">
                  <img src={cvcframe} alt="cvc-frame" />
                </div>
              </div>
            </div>
          </div>
          {commonMsg ? (
            <div style={{ paddingBottom: 0 }} className="ErrorMessage">
              {commonMsg}
            </div>
          ) : null}

          <div className="fieldWrapper">
            <div className="FieldTitle">Name on card</div>
            <div className={validName ? "errorgroup" : "group"}>
              <div className="fielditem">
                <input
                  className="field"
                  label="Name"
                  id="name"
                  type="text"
                  onBlur={validateName}
                  required
                  autoComplete="off"
                  value={billingDetails.name}
                  onChange={(e) => {
                    setBillingDetails({
                      ...billingDetails,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            {validName && <div className="ErrorMessage">{validName}</div>}
          </div>
        </div>

        <div className="fieldWrapper">
          <div className="FieldTitle">Country And Region</div>
          <div className="group">
            <div className="fielditem">
              <select
                onChange={countryfn}
                defaultValue={"US"}
                name="country"
                className="borderfield"
              >
                {countries.map((x, i) => (
                  <option className="state_option" key={i} value={x.code}>
                    {x.name}
                  </option>
                ))}
              </select>
            </div>
            <div
              className={
                props.erorrMsg === 502 || validZip
                  ? "zip-error"
                  : "fielditem-zip"
              }
            >
              <input
                className="field"
                label="ZIP"
                id="zip"
                type="text"
                placeholder="ZIP"
                onBlur={validateZip}
                required
                autoComplete="off"
                onChange={zipCodefn}
              />
            </div>
          </div>
          {validZip && <div className={"ErrorMessage"}>{validZip}</div>}
        </div>
        <div className="submit_btn_container">
          <SubmitButton
            className={
              buttonShadow ? "SubmitButton" : "SubmitButton sub-buttonz"
            }
            processing={btStatus}
            error={error}
            disabled={btStatus || props.amount === 0 || props.amount < 0}
          >
            PAY ${parseFloat(props.amount).toFixed(2)}
          </SubmitButton>
        </div>
        <div className="payment_prev_btn_container">
          <Button
            className="pay_previous_button"
            variant="outlined"
            color="primary"
            size="medium"
            onClick={handlePrev}
          >
            Go back
          </Button>
        </div>
      </form>
    </div>
  );
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const Stripe = (props) => {
  const accountId = localStorage.getItem("accountId");
  const stripePromise = accountId
    ? loadStripe(STRIPE_KEY, {
        stripeAccount: accountId,
      })
    : loadStripe(STRIPE_KEY);
  const [errStatus, SeterrStatus] = useState(false);
  const pin = localStorage.getItem("pin");

  const changeErStatus = (value) => {
    SeterrStatus(value);
  };
  const { paymentRes, paymentDetails } = useSelector((state) => state.checkin);
  useEffect(() => {
    if (paymentRes && paymentRes.data) {
      if (paymentRes.responseCode === 0) {
        toast.success("Payment successful");
        window.location.href = "/appointment";
        localStorage.setItem("paymentSuccess", 1);
      } else if (paymentDetails.responseCode === 113) {
        toast.error("Session expired. Please Login again");
        window.location.href = "/" + pin;
      } else {
        toast.error("Unknown error. Please login again");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRes.data]);

  return (
    <div className="main_stripe_container">
      <div className="stripe_container">
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <CheckoutForm
            changeErStatus={changeErStatus}
            makePayment={makePayment}
            visitId={props.visitId}
            visitIdOption={props.visitIdOption}
            amount={parseFloat(props.amount).toFixed(2)}
            errStatus={errStatus}
            handlePrev={props.handlePrev}
            paymentOption={props.paymentOption}
          />
        </Elements>
      </div>
    </div>
  );
};

export default Stripe;
