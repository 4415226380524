import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircleRounded';
import ICalendarLink from 'react-icalendar-link';
import './style.css';

// const days = [
//   'Monday',
//   'Tuesday',
//   'Wednesday',
//   'Thursday',
//   'Friday',
//   'Saturday',
//   'Sunday',
// ];

// const months = [
//   'January',
//   'February',
//   'March',
//   'April',
//   'May',
//   'June',
//   'July',
//   'August',
//   'September',
//   'October',
//   'November',
//   'December',
// ];

function AppointmentDetails() {
  const [success, setSuccess] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [event, setEvent] = useState({
    title: '',
    description: '',
    startTime: '',
    endTime: '',
    location: '',
  });

  function getDate(date) {
    if (date === null) {
      return null;
    }
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }
    if (Number.isNaN(year)) {
      return null;
    }
    return [month, day, year].join('/');
  }

  function getTime(time) {
    let t = new Date(time);
    let h = t.getHours();
    let ampm;
    if (h < 12) {
      ampm = 'AM';
    } else ampm = 'PM';
    if (h > 12) {
      h = Math.abs(12 - h);
    }
    let hours;
    if (h < 10 && h > 0) {
      hours = '0' + h.toString();
    } else if (h === 0) {
      hours = '12';
    } else hours = h.toString();
    let mins = t.getMinutes();
    let minutes;
    if (mins < 10) {
      minutes = '0' + mins.toString();
    } else minutes = mins.toString();
    return hours + ':' + minutes + ' ' + ampm;
  }

  const dateToIsoFormat = (dt) => {
    if (dt) {
      let d = new Date(dt.toString());
      let yr = d.getFullYear();
      let m = d.getMonth() + 1;
      if (m < 10) m = '0' + m.toString();
      let day = d.getDate();
      if (day < 10) day = '0' + day.toString();
      let hrs = d.getHours();
      if (hrs < 10) hrs = '0' + hrs.toString();
      let mins = d.getMinutes();
      if (mins < 10) mins = '0' + mins.toString();
      let sec = d.getSeconds();
      if (sec < 10) sec = '0' + sec.toString();
      return `${yr}${m}${day}T${hrs}${mins}${sec}`;
      // let d = new Date(dt);
      // let iso = d.toISOString();
      // let s = iso.split(".")[0].split("T");
      // let date = s[0].split("-").join("");
      // let time = s[1].split(":").join("");
      // console.log(date + "T" + time + "Z");
      // return date + "T" + time + "Z";
    }
  };

  const dateToIso = (dt) => {
    if (dt) {
      let d = new Date(dt.toString());
      let iso = d.toISOString();
      return iso;
    }
  };

  useEffect(() => {
    let s = parseInt(localStorage.getItem('paymentSuccess'));
    let a = JSON.parse(localStorage.getItem('apmtDetails'));
    setAppointment(a);
    setSuccess(s);
    const event = {
      title: a.clinicName + ' - ' + a.providerName,
      description:
        'Appointment with ' +
        a.providerName +
        ', ' +
        a.credentials +
        ' at ' +
        a.clinicName,
      startTime: dateToIso(a.startTime),
      endTime: dateToIso(a.endTime),
      location:
        a.clinicAddress1 +
        ', ' +
        a.clinicCity +
        ' ' +
        a.clinicZip +
        ', ' +
        a.clinicState,
    };
   
    setEvent(event);
  }, []);

  return (
    <div>
      <Grid
        container
        className="appointment_details_container"
      >
        <Grid
          xs={12}
          item
        >
          {success === 1 ? (
            <div className="payment_success_container">
              <Grid
                item
                xs={12}
                className="success_icon_container"
              >
                <CheckIcon
                  className="success_icon"
                  color="primary"
                  fontSize="large"
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="success_message_container"
              >
                <div className="success_message">Payment Successful</div>
              </Grid>
            </div>
          ) : (
            ''
          )}
        </Grid>
        <Grid
          xs={12}
          item
          className="apmt_details_container"
        >
          <p className="apmt_details">Your check-in was successful.</p>
        </Grid>
        <Grid
          xs={12}
          item
          className="details_heading"
        >
          <div className="details_heading_text">Appointment details</div>
        </Grid>
        <Grid
          xs={12}
          item
        >
          <div className="details_box">
            <Grid
              container
              className="appt_details_box_content"
            >
              <Grid
                item
                xs={12}
                className="appt_text_dark"
              >
                Appt:{' '}
                <span className="appt_text_light">
                  {getDate(appointment.startTime) +
                    ' at ' +
                    getTime(appointment.startTime)}
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                className="appt_text_dark"
              >
                Clinic:{' '}
                <span className="appt_text_light">
                  {appointment.clinicName}
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                className="appt_text_dark"
              >
                Provider:{' '}
                <span className="appt_text_light">
                  {appointment.credentials
                    ? appointment.providerName + ', ' + appointment.credentials
                    : appointment.providerName}
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                className="appt_text_dark"
              >
                Appt Type:{' '}
                <span className="appt_text_light">
                  {appointment.appointmentTypeName}
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                className="appt_text_dark"
              >
                Location:{' '}
                <span className="appt_text_light">
                  {appointment.clinicAddress1}
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                className="apt_address_content"
              >
                {appointment.clinicCity}, {appointment.clinicState}{' '}
                {appointment.clinicZip}
              </Grid>
              <Grid
                item
                xs={12}
                className="apt_address_content"
              >
                {appointment.clinicPhone}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid
          xs={12}
          item
          className="add_google_button_container"
        >
          <a
            href={
              'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=' +
              dateToIsoFormat(appointment.startTime) +
              '/' +
              dateToIsoFormat(appointment.endTime) +
              '&details=Appointment%20with%20Dr.%20' +
              appointment.providerName +
              '%20at%20' +
              appointment.clinicName +
              '&location=' +
              appointment.locationName +
              '&text=' +
              appointment.locationName +
              ' - ' +
              appointment.providerName
            }
          >
            <Button
              className="add_google_button"
              variant="contained"
              color="primary"
              size="medium"
            >
              Add to Google Calendar
            </Button>
          </a>
        </Grid>
        <Grid
          xs={12}
          item
          className="add_outlook_button_container"
        >
          {/* <a
            href={
              "https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=Appointment%20with%20Dr.%20" +
              appointment.providerName +
              "%20at%20" +
              appointment.locationName +
              "&startdt=" +
              dateToIso(appointment.startTime) +
              "&enddt=" +
              dateToIso(appointment.endTime) +
              "&location=" +
              appointment.locationName +
              "&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&subject=" +
              appointment.locationName +
              " - " +
              appointment.providerName
            }
          > */}
          <ICalendarLink
            filename="appointment.ics"
            event={event}
          >
            <Button
              className="add_outlook_button"
              variant="outlined"
              color="primary"
              size="medium"
            >
              Add to Apple Calendar
            </Button>
          </ICalendarLink>
          {/* </a> */}
        </Grid>
      </Grid>
    </div>
  );
}



export default AppointmentDetails
