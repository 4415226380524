import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { getDetails } from 'Redux/Checkin/newCheckin.actions';
import EditDetails from './edit';
import { toast } from 'react-hot-toast';
import './style.css';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

function PersonalDetails(props) {
  const [edit, setEdit] = useState(false);
  const [patient, setPatient] = useState({});
  const [appointment, setAppointment] = useState({});
  const [updated, setUpdated] = useState(false);
  const [pin, setPin] = useState('');
  const dispatch = useDispatch();
  const { detailsResponse } = useSelector((state) => state.checkin);
  function phoneFormat(ph) {
    let len = ph.length;
    if (len === 10) {
      return `(${ph.substring(0, 3)}) ${ph.substring(3, 6)}-${ph.substring(
        6,
        10
      )}`;
    } else {
      return ph;
    }
  }

  function getDate(date) {
    if (date === null) {
      return null;
    }
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }
    if (Number.isNaN(year)) {
      return null;
    }
    return [month, day, year].join('/');
  }

  function getTime(time) {
    let t = new Date(time);
    let h = t.getHours();
    let ampm;
    if (h < 12) {
      ampm = 'AM';
    } else ampm = 'PM';
    if (h > 12) {
      h = Math.abs(12 - h);
    }
    let hours;
    if (h < 10 && h > 0) {
      hours = '0' + h.toString();
    } else if (h === 0) {
      hours = '12';
    } else hours = h.toString();
    let mins = t.getMinutes();
    let minutes;
    if (mins < 10) {
      minutes = '0' + mins.toString();
    } else minutes = mins.toString();
    return hours + ':' + minutes + ' ' + ampm;
  }

  const handleNext = () => {
    window.location.href = '/insurance';
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const handleCancel = () => {
    setEdit(false);
    dispatch(getDetails(localStorage.getItem('pin')));
  };

  useEffect(() => {
    let p = localStorage.getItem('pin');
    dispatch(getDetails(p));
    setPin(p);
    localStorage.setItem('switchToCash', 0);
    localStorage.setItem('primaryInsuranceEdited', 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (detailsResponse && detailsResponse.data) {
      if (detailsResponse.responseCode === 0) {
        let data = detailsResponse?.data;

        let pt = JSON.parse(localStorage.getItem('updatedPatient'));
        if (pt) {
          setPatient(pt);
          setUpdated(true);
        } else {
          if (data.patientDto.addressLine1 && data.patientDto.phone) {
            setPatient(data.patientDto);
          } else {
            setEdit(true);
          }
        }
        setAppointment(data.appointmentDto);
        localStorage.setItem(
          'apmtDetails',
          JSON.stringify(data.appointmentDto)
        );
        localStorage.setItem('apmtId', data.appointmentDto.id);
        localStorage.setItem(
          'apmtTypeId',
          data.appointmentDto.appointmentTypeId
        );
        localStorage.setItem('clinicId', data.appointmentDto.clinicId);
        localStorage.setItem(
          'accountId',
          data.appointmentDto.connectedAccountId
        );
        localStorage.setItem('patientId', data.patientDto.id);

        localStorage.setItem(
          'apptEstimateEnabled',
          data.appointmentDto.appointmentEstimateEnabled
            ? data.appointmentDto.appointmentEstimateEnabled
            : 1
        );
      } else if (detailsResponse.responseCode === 113) {
        toast.error('Session expired. Please Login again');
        window.location.href = '/' + pin;
      } else {
        toast.error('Unknown error. Please login again');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsResponse.data]);

  return (
    <div>
      <Grid
        container
        className="personal_details_container"
      >
        <Grid
          xs={12}
          item
          className="apmt_details_container"
        >
          {appointment.clinicName ? (
            <div className="apmt_details">
              <div className="apmt_details_clinicName">
                {appointment?.clinicName}
              </div>
              <div className="apmt_details_line2">
                {appointment.appointmentTypeName +
                  ' - ' +
                  appointment.providerName +
                  ', ' +
                  appointment.credentials}
              </div>
              <div className="apmt_details_line3">
                {getDate(appointment.startTime) +
                  ' ' +
                  getTime(appointment.startTime)}
              </div>
            </div>
          ) : null}
        </Grid>
        {!edit ? (
          <>
            <Grid
              xs={12}
              item
              className="details_heading"
            >
              <div className="details_heading_text">
                {updated ? 'Confirm' : 'Verify'} your address
              </div>
            </Grid>
            <Grid
              xs={12}
              item
            >
              <div className="personal_details_box">
                <Grid
                  container
                  className="details_box_content"
                >
                  <Grid
                    item
                    xs={12}
                    className="patient_name"
                  >
                    {patient.firstName && patient.lastName
                      ? patient.firstName + ' ' + patient.lastName
                      : ''}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    className="address_content"
                  >
                    {patient.addressLine1 ? patient.addressLine1 : ''}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="address_content"
                  >
                    {patient.city ? patient.city + ', ' : ''}
                    {patient.zip
                      ? patient.state + ' ' + patient.zip.substring(0, 5)
                      : patient.state}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="address_content"
                  >
                    {patient.phone ? phoneFormat(patient.phone) : ''}
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid
              xs={3}
              item
              className="edit_button_container"
            >
              <Button
                className="edit_button"
                variant="outlined"
                color="primary"
                size="medium"
                onClick={handleEdit}
              >
                Edit
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
            ></Grid>
            <Grid
              xs={3}
              item
              className="next_button_container"
            >
              <Button
                className="next_button"
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleNext}
              >
                Next
              </Button>
            </Grid>
          </>
        ) : (
          <EditDetails
            id={patient.id}
            clinicId={appointment.clinicId}
            handleCancel={handleCancel}
          />
        )}
      </Grid>
    </div>
  );
}
export default PersonalDetails;
