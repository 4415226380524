import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./Login/newLogin.reducers";
import CheckinReducer from "./Checkin/newCheckin.reducers";
import { combineReducers } from "redux";
const rootReducer = combineReducers({
  login: loginReducer,
  checkin: CheckinReducer,
});
const newStore = configureStore({
  reducer: rootReducer,
});

export default newStore;
