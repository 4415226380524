import httpCheckin from 'Redux/http/http-checkin';
import httpPayment from 'Redux/http/http-payment';
class Checkin {
  getUserDetails(data) {
    return httpCheckin.get(`trillium-patient-service/v1/checkin/${data}`);
  }
  saveDetails(data) {
    return httpCheckin.put(`trillium-patient-service/v1/checkin/patient`, data);
  }
  getUserInsuranceDetails(data) {
    return httpCheckin.get(
      `trillium-patient-service/v1/checkin/insurace/${data}`
    );
  }
  insuranceCheckin(data) {
    return httpCheckin.post(
      `trillium-patient-service/v1/checkin/appointment/checked/${data}`
    );
  }
  saveUserInsurance(data) {
    return httpCheckin.post(
      `trillium-patient-service/v1/checkin/insurace`,
      data
    );
  }
  patientPayment(data) {
    return httpCheckin.get(
      `trillium-patient-service/v1/checkin/allowed?appointmentTypeId=${data.typeId}&clinicId=${data.clinicId}&payerId=${data.payerId}&patientId=${data.patientId}&appointmentId=${data.appointmentId}`
    );
  }
  getPaymentDetails(data) {
    return httpCheckin.get(
      `trillium-patient-service/v1/checkin/balance/${data}`
    );
  }
  searchPayor(data) {
    return httpCheckin.get(
      `trillium-patient-service/v1/payer/search/name?name=${data.str}&clinicId=${data.id}`
    );
  }
  makePayment(data) {
    return httpPayment.post(
      `trillium-patient-service/v1/checkin/payment`,
      data
    );
  }
  makeCashPayment(data) {
    return httpCheckin.put(
      `trillium-patient-service/v1/checkin/insurace/inActivate/${data.patientId}?appointmentId=${data.appointmentId}&appointmentTypeId=${data.appointmentTypeId}`
    );
  }
}

const CheckinService = new Checkin();

export default CheckinService;
