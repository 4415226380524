import { Button, FormControlLabel, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@material-ui/core";
import {
  saveInsurance,
  searchPayor,
  makeCashPatient,
} from "Redux/Checkin/newCheckin.actions";
import Input from "components/Input";
import { Autocomplete } from "@material-ui/lab";
import Dropdown from "components/Dropdown";
import DatePicker from "components/DatePicker";
import { toast } from "react-hot-toast";
import "./style.css";
const relation = [
  {
    name: "FATHER",
    value: 1,
  },
  {
    name: "MOTHER",
    value: 2,
  },
  {
    name: "SPOUSE",
    value: 3,
  },
  {
    name: "BROTHER",
    value: 4,
  },
  {
    name: "SISTER",
    value: 5,
  },
  {
    name: "OTHER",
    value: 6,
  },
  {
    name: "SELF",
    value: 7,
  },
  {
    name: "GUARANTOR",
    value: 8,
  },
  {
    name: "CHILD",
    value: 9,
  },
  {
    name: "EMPLOYEE",
    value: 10,
  },
  {
    name: "UNKNOWN",
    value: 11,
  },
  {
    name: "ORGANDONOR",
    value: 12,
  },
  {
    name: "CADAVERDONOR",
    value: 13,
  },
  {
    name: "LIFEPARTNER",
    value: 14,
  },
];

const insuranceTypes = [
  { name: "Insurance", value: 9 },
  { name: "PIP", value: 7 },
  { name: "WC", value: 6 },
  { name: "Other ", value: 5 },
  { name: "HMO ", value: 3 },
];

function EditInsurance(props) {
  const patientId = parseInt(localStorage.getItem("patientId"));
  const apmtId = parseInt(localStorage.getItem("apmtId"));
  const apmtTypeId = parseInt(localStorage.getItem("apmtTypeId"));
  const [isChecked, setIsChecked] = useState(false);
  const [isCashPatient, setIsCashPatient] = useState(false);
  const [gotSecIns, setGotSecIns] = useState(false);
  const [clickedNext, setClickedNext] = useState(false);
  const [saveSec, setSaveSec] = useState(false);
  const [editPrimeHolder, setEditPrimeHolder] = useState(false);
  const [editSecHolder, setEditSecHolder] = useState(false);
  const [primaryPayor, setPrimaryPayor] = useState({ id: 0, payerName: "" });
  const [secPayor, setSecPayor] = useState({ id: 0, payerName: "" });
  const [primaryDob, setPrimaryDob] = useState(null);
  const [secondaryDob, setSecondaryDob] = useState(null);
  const [payorList, setPayorList] = useState([]);
  // const [totalInsurance, setTotalInsurance] = useState([]);
  const [primaryPlan, setPrimaryPlan] = useState(9);
  const [secPlan, setSecPlan] = useState(9);
  const [primaryIns, setPrimaryIns] = useState({
    insuranceId: null,
    patientId: "",
    policyHolderFName: "",
    policyHolderLName: "",
    policyHolderDob: null,
    policyNumber: "",
    groupNumber: "",
    payorName: "",
    businessId: "",
    insuranceType: null,
    ranking: 1,
    copay: "0",
    coIns: "0",
    deductable: 0,
    relationship: 7,
  });
  const [secondaryIns, setSecondaryIns] = useState({
    insuranceId: null,
    patientId: "",
    policyHolderFName: "",
    policyHolderLName: "",
    policyHolderDob: null,
    policyNumber: "",
    groupNumber: "",
    payorName: "",
    businessId: "",
    insuranceType: null,
    ranking: 2,
    copay: "0",
    coIns: "0",
    deductable: 0,
    relationship: 7,
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
  });
  const { payorLists, saveInsuranceRes, makeCashPatientRes } = useSelector(
    (state) => state.checkin
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setPrimaryIns({
      ...primaryIns,
      insuranceId: props.primary.insuranceId ? props.primary.insuranceId : null,
      patientId: patientId ? patientId : "",
      policyHolderDob: formatDate(primaryDob),
      businessId: primaryPayor ? primaryPayor.id : "",
      payorName: primaryPayor ? primaryPayor.payerName : "",
      insuranceType: primaryPayor ? primaryPayor.insuranceType : null,
      active: 1,
    });
    setSecondaryIns({
      ...secondaryIns,
      insuranceId: props.secondary.insuranceId
        ? props.secondary.insuranceId
        : null,
      patientId: patientId ? patientId : "",
      policyHolderDob: formatDate(secondaryDob),
      businessId: secPayor ? secPayor.id : "",
      payorName: secPayor ? secPayor.payerName : "",
      insuranceType: secPayor ? secPayor.insuranceType : null,
      active: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryPayor, secPayor, primaryDob, secondaryDob]);

  useEffect(() => {}, []);

  const formatDate = (date) => {
    if (date === null) {
      return null;
    }
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    if (isNaN(year)) {
      return null;
    } else {
      return [year, month, day].join("-");
    }
  };

  const handlePrimaryDob = (e) => {
    setPrimaryDob(e);
  };

  const handleSecondaryDob = (e) => {
    setSecondaryDob(e);
  };

  const handlePrimePolicyHolder = (e) => {
    if (e.target.value !== 7) {
      setEditPrimeHolder(true);
    } else {
      setEditPrimeHolder(false);
    }
    setPrimaryIns({
      ...primaryIns,
      relationship: e.target.value,
    });
  };

  const handleSecPolicyHolder = (e) => {
    if (e.target.value !== 7) {
      setEditSecHolder(true);
    } else {
      setEditSecHolder(false);
    }
    setSecondaryIns({
      ...secondaryIns,
      relationship: e.target.value,
    });
  };

  const handleCancelPrime = () => {
    props.handleCancel();
  };

  const handleCancelSec = () => {
    if (props.insOption === "4" || props.insOption === "6") {
      props.handleCancel();
    } else {
      setClickedNext(false);
      setGotSecIns(false);
      setIsChecked(false);
    }
  };

  useEffect(() => {
    if (props.insOption) {
      if (props.insOption === "4" || props.insOption === "6") {
        setClickedNext(true);
        setGotSecIns(true);
        setIsChecked(true);
      }
    }
  }, [props.insOption]);

  const handleCashPatient = () => {
    const prime = parseInt(localStorage.getItem("primaryInsuranceExists"));
    localStorage.setItem("switchToCash", 1);
    if (prime) {
      dispatch(
        makeCashPatient({
          patientId: patientId,
          appointmentId: apmtId,
          appointmentTypeId: apmtTypeId,
        })
      );
    } else {
      props.handleCheckin();
    }
  };

  const handleSetCashPatient = () => {
    setIsCashPatient(!isCashPatient);
  };

  const handleConfirmPrime = () => {
    if (isChecked) {
      if (
        errors.firstName === "" &&
        errors.lastName === "" &&
        primaryIns.policyNumber
        // && primaryIns.payorName
      ) {
        setGotSecIns(true);
        setClickedNext(true);
        dispatch(
          saveInsurance({
            ...primaryIns,
            insuranceType: parseInt(primaryPlan),
            appointmentId: apmtId,
            appointmentTypeId: apmtTypeId,
          })
        );
      } else {
        toast.error("Please enter valid values");
      }
    } else {
      if (
        errors.firstName === "" &&
        errors.lastName === "" &&
        primaryIns.policyNumber
        // && primaryIns.payorName
      ) {
        dispatch(
          saveInsurance({
            ...primaryIns,
            insuranceType: parseInt(primaryPlan),
            appointmentId: apmtId,
            appointmentTypeId: apmtTypeId,
          })
        );
      } else {
        toast.error("Please enter valid values");
      }
    }
  };

  const handleConfirmSec = () => {
    if (
      errors.firstName === "" &&
      errors.lastName === "" &&
      secondaryIns.policyNumber
    ) {
      dispatch(
        saveInsurance({
          ...secondaryIns,
          insuranceType: parseInt(secPlan),
          appointmentId: apmtId,
          appointmentTypeId: apmtTypeId,
        })
      );
    } else {
      toast.error("Please enter valid values");
    }
  };

  // const handleConfirmSec = () => {
  //   if (
  //     errors.firstName === "" &&
  //     errors.lastName === "" &&
  //     secondaryIns.policyNumber
  //   ) {
  //     let t = totalInsurance;
  //     t.push(secondaryIns);
  //     setTotalInsurance(t);
  //     props.saveInsurance({
  //       patientId: patientId,
  //       insurance: t,
  //     });
  //     props.handleUpdate();
  //   } else {
  //     toast.error("Please enter valid values");
  //   }
  // };

  const searchPayorFn = (val) => {
    // let cId = parseInt(localStorage.getItem("clinicId"));
    dispatch(searchPayor({ str: val, id: 83622 }));
  };

  useEffect(() => {
    if (payorLists) {
      if (payorLists.responseCode === 0) {
        let data = payorLists.data;

        setPayorList(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payorLists]);

  useEffect(() => {
    if (makeCashPatientRes && makeCashPatientRes.data) {
      if (makeCashPatientRes.responseCode === 0) {
        props.handleCheckin();
      } else {
        toast.error("Something went wrong. Try again later");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeCashPatientRes.data]);

  useEffect(() => {
    if (saveInsuranceRes && saveInsuranceRes.data) {
      if (saveInsuranceRes.responseCode === 0) {
        let data = saveInsuranceRes.data.object;
        const payorId = parseInt(localStorage.getItem("primaryPayorId"));
        if (props.insOption === "2") {
          if (payorId !== data.businessId) {
            localStorage.setItem("primaryInsuranceEdited", 1);
            localStorage.setItem("primaryPayorId", payorId);
          }
          if (gotSecIns && clickedNext && !saveSec) {
            setSaveSec(true);
          } else if (gotSecIns && clickedNext && saveSec) {
            props.handleCancel();
          } else {
            props.handleCancel();
          }
          // if (isCashPatient) {
          //   if (props.secondary.insuranceId) {
          //     props.saveInsurance({
          //       insuranceId: props.secondary.insuranceId,
          //       patientId: patientId,
          //       active: 0,
          //       appointmentId: apmtId,
          //       appointmentTypeId: apmtTypeId,
          //     });
          //     props.handleCheckin();
          //   } else {
          //     props.handleCheckin();
          //   }
          // }
        } else if (
          props.insOption === "4" &&
          gotSecIns &&
          clickedNext &&
          !saveSec
        ) {
          props.handleCancel();
        } else if (
          props.insOption === "6" &&
          gotSecIns &&
          clickedNext &&
          !saveSec
        ) {
          props.handleCancel();
        } else if (gotSecIns && clickedNext && !saveSec) {
          setSaveSec(true);
        } else if (gotSecIns && clickedNext && saveSec) {
          props.handleCancel();
        } else {
          localStorage.setItem("primaryInsuranceEdited", 1);
          localStorage.setItem("switchToCash", 0);
          props.handleCancel();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveInsuranceRes]);

  const validateFirstName = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
      setErrors({ ...errors, firstName: "Enter a valid Name" });
    } else {
      setErrors({ ...errors, firstName: "" });
    }
  };

  const validateLastName = (value) => {
    if (!/^[a-zA-Z][a-zA-Z-0-9 -']+$/.test(value)) {
      setErrors({ ...errors, lastName: "Enter a valid Name" });
    } else {
      setErrors({ ...errors, lastName: "" });
    }
  };

  return (
    <>
      <Grid xs={12} item className="details_heading">
        <div className="details_heading_text">
          {props.insOption === "2" || props.insOption === "6" ? "Edit" : "Add"}{" "}
          your insurance details
        </div>
      </Grid>
      {!gotSecIns && !clickedNext ? (
        <>
          <Grid xs={12} item className="edit_prime_ins_container">
            <div
              className={
                editPrimeHolder && errors.firstName !== ""
                  ? "edit_prime_holder_box_errors"
                  : editPrimeHolder && errors.firstName === ""
                  ? "edit_prime_holder_box"
                  : "edit_prime_details_box"
              }
            >
              <Grid container className="edit_details_box_content" spacing={2}>
                <Grid item xs={12} className="ins_heading">
                  Primary Insurance
                </Grid>
                <Grid item xs={12} className="insurance_name">
                  <div>
                    <Autocomplete
                      fullWidth
                      options={payorList}
                      onChange={(e, v) => setPrimaryPayor(v)}
                      value={primaryPayor}
                      getOptionLabel={(option) => option.payerName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          // variant="outlined"
                          label="Insurance name"
                          placeholder="Search insurance"
                          onChange={(e) => searchPayorFn(e.target.value)}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} className="plan_name">
                  {/* <Input
                    type="text"
                    label="Insurance"
                    value={primaryPlan}
                    onChange={(e) => setPrimaryPlan(e.target.value)}
                  /> */}
                  <Dropdown
                    options={insuranceTypes}
                    label="Insurance Type"
                    // variant="outlined"
                    onChange={(e) => setPrimaryPlan(e.target.value)}
                    value={primaryPlan}
                  />
                </Grid>
                <Grid item xs={12} className="edit_text_box">
                  <Input
                    type="text"
                    required
                    label="Policy/Subscriber number"
                    value={primaryIns.policyNumber}
                    onChange={(e) =>
                      setPrimaryIns({
                        ...primaryIns,
                        policyNumber: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} className="edit_text_box">
                  <Input
                    type="text"
                    label="Group number (optional)"
                    value={primaryIns.groupNumber}
                    onChange={(e) =>
                      setPrimaryIns({
                        ...primaryIns,
                        groupNumber: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} className="edit_text_box">
                  <Dropdown
                    options={relation}
                    label="Policy Holder"
                    // variant="outlined"
                    onChange={handlePrimePolicyHolder}
                    value={primaryIns.relationship}
                  />
                </Grid>
                {editPrimeHolder && (
                  <>
                    <Grid item xs={12} className="edit_text_box">
                      <Input
                        type="text"
                        required
                        label="Policy holder first name"
                        value={primaryIns.policyHolderFName}
                        onChange={(e) =>
                          setPrimaryIns({
                            ...primaryIns,
                            policyHolderFName: e.target.value,
                          })
                        }
                        onBlur={(e) => validateFirstName(e.target.value)}
                        error={errors.firstName ? true : false}
                        helperText={errors.firstName}
                      />
                    </Grid>
                    <Grid item xs={12} className="edit_text_box">
                      <Input
                        type="text"
                        required
                        label="Policy holder last name"
                        value={primaryIns.policyHolderLName}
                        onChange={(e) =>
                          setPrimaryIns({
                            ...primaryIns,
                            policyHolderLName: e.target.value,
                          })
                        }
                        onBlur={(e) => validateLastName(e.target.value)}
                        error={errors.lastName ? true : false}
                        helperText={errors.lastName}
                      />
                    </Grid>
                    <Grid item xs={12} className="edit_text_box">
                      <DatePicker
                        required
                        label="Policy holder DOB"
                        value={primaryDob}
                        maxDate={new Date()}
                        onChange={(e) => handlePrimaryDob(e)}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          </Grid>
          <Grid xs={12} item className="checkbox_container">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCashPatient}
                  // value={isChecked}
                  disabled={isChecked}
                  onChange={() => handleSetCashPatient()}
                  name="insurance_checkbox"
                  color="primary"
                />
              }
              label="I'm a cash patient. I don't have insurance"
              size="large"
            />
          </Grid>
          <Grid xs={12} item className="checkbox_container">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  disabled={isCashPatient}
                  // value={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                  name="insurance_checkbox"
                  color="primary"
                />
              }
              label="I have a secondary insurance"
              size="large"
            />
          </Grid>

          <Grid xs={3} item className="cancel_button_container">
            <Button
              className="cancel_button"
              variant="outlined"
              color="primary"
              size="medium"
              onClick={handleCancelPrime}
            >
              Prev
            </Button>
          </Grid>

          <Grid item xs={5}></Grid>

          <Grid xs={4} item className="confirm_button_container">
            <Button
              className="confirm_button"
              variant="contained"
              color="primary"
              size="medium"
              onClick={isCashPatient ? handleCashPatient : handleConfirmPrime}
            >
              {isCashPatient ? "Check in" : "Next"}
            </Button>
          </Grid>
        </>
      ) : (
        <>
          <Grid xs={12} item className="edit_sec_ins_container">
            <div
              className={
                editSecHolder && errors.firstName !== ""
                  ? "edit_sec_holder_box_errors"
                  : editSecHolder && errors.firstName === ""
                  ? "edit_sec_holder_box"
                  : "edit_sec_details_box"
              }
            >
              <Grid container className="edit_details_box_content" spacing={2}>
                <Grid item xs={12} className="ins_heading">
                  Secondary Insurance
                </Grid>
                <Grid item xs={12} className="insurance_name">
                  <Autocomplete
                    fullWidth
                    options={payorList}
                    onChange={(e, v) => setSecPayor(v)}
                    value={secPayor}
                    getOptionLabel={(option) => option.payerName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        // variant="outlined"
                        label="Insurance name"
                        placeholder="Search insurance"
                        onChange={(e) => searchPayorFn(e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} className="insurance_name">
                  {/* <Input
                    type="text"
                    label="Plan name"
                    value={secPlan}
                    onChange={(e) => setSecPlan(e.target.value)}
                  /> */}
                  <Dropdown
                    options={insuranceTypes}
                    label="Insurance Type"
                    // variant="outlined"
                    onChange={(e) => setSecPlan(e.target.value)}
                    value={secPlan}
                  />
                </Grid>
                <Grid item xs={12} className="edit_text_box">
                  <Input
                    type="text"
                    required
                    label="Policy/Subscriber number"
                    value={secondaryIns.policyNumber}
                    onChange={(e) =>
                      setSecondaryIns({
                        ...secondaryIns,
                        policyNumber: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} className="edit_text_box">
                  <Input
                    type="text"
                    label="Group number (optional)"
                    value={secondaryIns.groupNumber}
                    onChange={(e) =>
                      setSecondaryIns({
                        ...secondaryIns,
                        groupNumber: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12} className="edit_text_box">
                  <Dropdown
                    options={relation}
                    label="Policy Holder"
                    // variant="outlined"
                    onChange={handleSecPolicyHolder}
                    value={secondaryIns.relationship}
                  />
                </Grid>
                {editSecHolder && (
                  <>
                    <Grid item xs={12} className="edit_text_box">
                      <Input
                        type="text"
                        required
                        label="Policy holder first name"
                        value={secondaryIns.policyHolderFName}
                        onChange={(e) =>
                          setSecondaryIns({
                            ...secondaryIns,
                            policyHolderFName: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} className="edit_text_box">
                      <Input
                        type="text"
                        required
                        label="Policy holder last name"
                        value={secondaryIns.policyHolderLName}
                        onChange={(e) =>
                          setSecondaryIns({
                            ...secondaryIns,
                            policyHolderLName: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} className="edit_text_box">
                      <DatePicker
                        required
                        label="Policy holder DOB"
                        value={secondaryDob}
                        maxDate={new Date()}
                        onChange={(e) => handleSecondaryDob(e)}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          </Grid>

          <Grid xs={3} item className="cancel_button_container">
            <Button
              className="cancel_button"
              variant="outlined"
              color="primary"
              size="medium"
              onClick={handleCancelSec}
            >
              Prev
            </Button>
          </Grid>

          <Grid item xs={6}></Grid>

          <Grid xs={3} item className="confirm_button_container">
            <Button
              className="confirm_button"
              variant="contained"
              color="primary"
              size="medium"
              onClick={handleConfirmSec}
            >
              Next
            </Button>
          </Grid>
        </>
      )}
    </>
  );
}
export default EditInsurance;
