import { createAsyncThunk } from "@reduxjs/toolkit";
import CheckinService from './newCheckin.services'
export const getDetails = createAsyncThunk("checkin/getDetails", async (credentials) => {
  try {

    const res = await CheckinService.getUserDetails(credentials);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const savePersonalDetails = createAsyncThunk(
  "checkin/saveDetails",
  async (credentials) => {
    try {
    
      const res = await CheckinService.saveDetails(credentials);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getInsurance=createAsyncThunk(
  "checkin/getInsurance",
  async (credentials) => {
    try {
      const res = await CheckinService.getUserInsuranceDetails(credentials);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const saveInsurance = createAsyncThunk(
  "checkin/saveInsurance",
  async (credentials) => {
    try {
      const res = await CheckinService.saveUserInsurance(credentials);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const makeCashPatient = createAsyncThunk(
  "checkin/makeCashPatient",
  async (credentials) => {
    try {
      const res = await CheckinService.makeCashPayment(credentials);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const checkin = createAsyncThunk(
  "checkin/insuranceCheckin",
  async (credentials) => {
    try {
      const res = await CheckinService.insuranceCheckin(credentials);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getCashPatientAmount = createAsyncThunk(
  "checkin/getPatientAmount",
  async (credentials) => {
 
    try {
      const res = await CheckinService.patientPayment(credentials);
      console.log(res.data, "cash patient data 73");
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
 export const getPaymentDetails = createAsyncThunk(
   "checkin/paymentDetails",
   async (credentials) => {
     try {
       const res = await CheckinService.getPaymentDetails(credentials);
       return res.data;
     } catch (error) {
       console.log(error);
     }
   }
 );
 export const searchPayor = createAsyncThunk(
   "checkin/searchPayor",
   async (credentials) => {
     try {
       const res = await CheckinService.searchPayor(credentials);
       return res.data;
     } catch (error) {
       console.log(error);
     }
   }
 );
 export const makePayment = createAsyncThunk(
   "checkin/makePayment",
   async (credentials) => {
     try {
       const res = await CheckinService.makePayment(credentials);
       return res.data;
     } catch (error) {
       console.log(error);
     }
   }
 );