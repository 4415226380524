import {createAsyncThunk} from  '@reduxjs/toolkit'
import LoginService from './newLogin.services'
export const login = createAsyncThunk("login/userLogin", async (credentials) => {
  try {
    const res = await LoginService.userLogin(credentials);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const LoginData = createAsyncThunk(
  "login/loginData",
  async (credentials) => {
    try {
      const res = await LoginService.getLoginData(credentials);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

