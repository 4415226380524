import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import {
  getInsurance,
  checkin,
  saveInsurance,
  makeCashPatient,
} from 'Redux/Checkin/newCheckin.actions';
import { getDetails } from 'Redux/Checkin/newCheckin.actions';
import { toast } from 'react-hot-toast';
import EditInsurance from './edit';
import Loader from 'components/Loader';
import './style.css';
import { useDispatch } from 'react-redux';
import { updateData } from 'Redux/Checkin/newCheckin.reducers';

function InsuranceDetails() {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [appointment, setAppointment] = useState({});
  const [patientId, setPatientId] = useState('');
  const [primary, setPrimary] = useState({});
  const [secondary, setSecondary] = useState({});
  const [showEstimate, setShowEstimate] = useState(false);
  const [apptEstimate, setApptEstimate] = useState(false);
  const [insOption, setInsOption] = useState('1');
  const [secDeleted, setSecDeleted] = useState(false);
  const pin = localStorage.getItem('pin');
  const apmtId = parseInt(localStorage.getItem('apmtId'));
  const apmtTypeId = parseInt(localStorage.getItem('apmtTypeId'));
  const dispatch = useDispatch();
  let {
    detailsResponse,
    checkinRes,
    insuranceRes,
    saveInsuranceRes,
    makeCashPatientRes,
  } = useSelector((state) => state.checkin);
  function getDate(date) {
    if (date === null) {
      return null;
    }
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }
    if (Number.isNaN(year)) {
      return null;
    }
    return [month, day, year].join('/');
  }

  function getTime(time) {
    let t = new Date(time);
    let h = t.getHours();
    let ampm;
    if (h < 12) {
      ampm = 'AM';
    } else ampm = 'PM';
    if (h > 12) {
      h = Math.abs(12 - h);
    }
    let hours;
    if (h < 10 && h > 0) {
      hours = '0' + h.toString();
    } else if (h === 0) {
      hours = '12';
    } else hours = h.toString();
    let mins = t.getMinutes();
    let minutes;
    if (mins < 10) {
      minutes = '0' + mins.toString();
    } else minutes = mins.toString();
    return hours + ':' + minutes + ' ' + ampm;
  }

  const handleCancel = () => {
    setEdit(false);
    let pin = localStorage.getItem('pin');
    dispatch(getDetails(pin));
    dispatch(updateData());
  };

  const handlePrev = () => {
    window.location.href = '/details';
  };

  const handleChangeInsOption = (e) => {
    setInsOption(e.target.value);
  };

  const handleCheckin = () => {
    setLoading(true);
    dispatch(checkin(localStorage.getItem('apmtId')));
  };

  useEffect(() => {
    if (pin) {
      dispatch(getDetails(pin));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  useEffect(() => {
    if (detailsResponse && detailsResponse.data) {
      if (detailsResponse.responseCode === 0) {
        let p = localStorage.getItem('patientId');
        dispatch(getInsurance(parseInt(p)));
        setInsOption('1');
      } else if (detailsResponse.responseCode === 113) {
        toast.error('Session expired. Please Login again');
        localStorage.clear();
        window.location.href = '/' + pin;
      } else {
        toast.error('Unknown error. Please login again');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsResponse.data]);

  useEffect(() => {
    let a = localStorage.getItem('apmtDetails');
    setAppointment(JSON.parse(a));
    let est = parseInt(localStorage.getItem('showEstimate'));
    setShowEstimate(est);
    let apptEst = parseInt(localStorage.getItem('apptEstimateEnabled'));
    setApptEstimate(apptEst);
    let p = localStorage.getItem('patientId');
    dispatch(getInsurance(parseInt(p)));
    setPatientId(parseInt(p));
    setInsOption('1');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (insuranceRes && insuranceRes.data) {
      if (insuranceRes.responseCode === 0) {
        let data = insuranceRes.data;
        data.forEach((x) => {
          if (x.ranking === 1) {
            setPrimary(x);
          }
        });
        data.forEach((x) => {
          if (x.ranking === 2) {
            setSecondary(x);
          }
        });
        let prime = data.filter((item) => item.ranking === 1);
        if (prime.length === 0) {
          setLoading(false);
          localStorage.setItem('primaryInsuranceExists', 0);
          setEdit(true);
        } else {
          setLoading(false);
          localStorage.setItem('primaryInsuranceExists', 1);
          localStorage.setItem('primaryPayorId', prime[0].businessId);
        }
      } else if (insuranceRes.responseCode === 113) {
        toast.error('Session expired. Please Login again');
        window.location.href = '/' + pin;
      } else {
        toast.error('Unknown error. Please login again');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceRes.data]);

  useEffect(() => {
    if (checkinRes) {
      if (checkinRes.responseCode === 0) {
        // setLoading(false);
        if (showEstimate && apptEstimate) {
          window.location.href = '/payment';
        } else if (showEstimate || apptEstimate) {
          if (apptEstimate) window.location.href = '/payment';
          else window.location.href = '/appointment';
        } else {
          window.location.href = '/appointment';
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkinRes.data]);

  const handleNext = () => {
    // Insurance Information is correct
    if (insOption === '1') {
      handleCheckin();
    }
    // Replace primary insurance
    else if (insOption === '2') {
      setEdit(true);
    }
    // I do not have insurance
    else if (insOption === '3') {
      // setPrimary({});
      localStorage.setItem('switchToCash', 1);
      dispatch(
        makeCashPatient({
          patientId: patientId,
          appointmentId: apmtId,
          appointmentTypeId: apmtTypeId,
        })
      );
      setLoading(true);
    }
    // Add secondary insurance
    else if (insOption === '4') {
      setEdit(true);
    }
    // Delete my secondary insurance
    else if (insOption === '5') {
      setSecondary({});
      dispatch(
        saveInsurance({
          insuranceId: secondary.insuranceId,
          patientId: patientId,
          active: 0,
          appointmentId: apmtId,
          appointmentTypeId: apmtTypeId,
        })
      );
    }
    // Replace my secondary insurance
    else if (insOption === '6') {
      setEdit(true);
    }
  };

  useEffect(() => {
    if (saveInsuranceRes && saveInsuranceRes.data) {
      if (saveInsuranceRes.responseCode === 0) {
        if (insOption === '3') {
          // localStorage.setItem('switchToCash', 1);
          if (secDeleted) {
            handleCheckin();
          } else {
            if (JSON.stringify(secondary) !== '{}') {
              setSecondary({});
              dispatch(
                saveInsurance({
                  insuranceId: secondary.insuranceId,
                  patientId: patientId,
                  active: 0,
                  appointmentId: apmtId,
                  appointmentTypeId: apmtTypeId,
                })
              );
              setSecDeleted(true);
            } else {
              handleCheckin();
            }
          }
        } else if (insOption === '2') {
          setInsOption('1');
          toast.success('Primary insurance updated');
        } else if (insOption === '5') {
          setInsOption('1');
          toast.success('Secondary insurance removed');
        } else if (insOption === '4') {
          handleCancel();
          setInsOption('1');
          toast.success('Secondary insurance added');
        } else if (insOption === '6') {
          setInsOption('1');
          toast.success('Secondary insurance updated');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveInsuranceRes.data]);

  useEffect(() => {
    if (makeCashPatientRes && makeCashPatientRes.data) {
      if (makeCashPatientRes.responseCode === 0) {
        setPrimary({});
        setSecondary({});
        handleCheckin();
      } else {
        toast.error('Something went wrong. Try again later');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeCashPatientRes.data]);

  let render = loading ? (
    <Loader />
  ) : (
    <div>
      <Grid
        container
        className="insurance_details_container"
      >
        <Grid
          xs={12}
          item
          className="apmt_details_container"
        >
          <div className="apmt_details">
            <div className="apmt_details_clinicName">
              {appointment.clinicName}
            </div>
            <div className="apmt_details_line2">
              {appointment.appointmentTypeName +
                ' - ' +
                appointment.providerName +
                ', ' +
                appointment.credentials}
            </div>
            <div className="apmt_details_line3">
              {getDate(appointment.startTime) +
                ' ' +
                getTime(appointment.startTime)}
            </div>
          </div>
        </Grid>
        {!edit ? (
          <>
            <Grid
              xs={12}
              item
              className="details_heading"
            >
              <div className="details_heading_text">
                Verify your insurance details
              </div>
            </Grid>

            {JSON.stringify(primary) === '{}' ? (
              ''
            ) : (
              <Grid
                xs={12}
                item
                className="prime_ins_container"
              >
                <div className="prime_ins_box">
                  <Grid
                    container
                    className="prime_ins_box_content"
                  >
                    <Grid
                      item
                      xs={12}
                      className="insurance_heading"
                    >
                      Primary Insurance
                    </Grid>
                    {primary ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          className="insurance_name"
                        >
                          {primary.payorName}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className="insurance_content"
                        >
                          Policy number: {primary.policyNumber}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className="insurance_content"
                        >
                          Group number: {primary.groupNumber}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className="insurance_content"
                        >
                          Policy holder:{' '}
                          {primary.policyHolderFName
                            ? primary.policyHolderFName +
                              primary.policyHolderLName
                            : ''}
                        </Grid>
                      </>
                    ) : (
                      ''
                    )}
                  </Grid>
                </div>
              </Grid>
            )}

            {JSON.stringify(secondary) === '{}' ? (
              ''
            ) : (
              <Grid
                xs={12}
                item
                className="sec_ins_container"
              >
                <div className="sec_ins_box">
                  <Grid
                    container
                    className="sec_ins_box_content"
                  >
                    <Grid
                      item
                      xs={12}
                      className="insurance_heading"
                    >
                      Secondary Insurance
                    </Grid>
                    {secondary ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          className="insurance_name"
                        >
                          {secondary.payorName}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className="insurance_content"
                        >
                          Policy number: {secondary.policyNumber}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className="insurance_content"
                        >
                          Group number: {secondary.groupNumber}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className="insurance_content"
                        >
                          Policy holder:{' '}
                          {secondary.policyHolderFName
                            ? secondary.policyHolderFName +
                              secondary.policyHolderLName
                            : ''}
                        </Grid>
                      </>
                    ) : (
                      ''
                    )}
                  </Grid>
                </div>
              </Grid>
            )}

            <Grid
              xs={12}
              item
              className="payment_options_container"
            >
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="options"
                  name="options"
                  value={insOption}
                  onChange={handleChangeInsOption}
                >
                  <FormControlLabel
                    value="1"
                    control={
                      <Radio
                        size="small"
                        color="primary"
                      />
                    }
                    label={'Insurance information is correct'}
                  />

                  <FormControlLabel
                    value="3"
                    control={
                      <Radio
                        size="small"
                        color="primary"
                      />
                    }
                    label={'I do not have insurance'}
                  />

                  <FormControlLabel
                    value="2"
                    control={
                      <Radio
                        size="small"
                        color="primary"
                      />
                    }
                    label={'Replace my primary insurance'}
                  />

                  {JSON.stringify(secondary) === '{}' ? (
                    ''
                  ) : (
                    <FormControlLabel
                      value="6"
                      control={
                        <Radio
                          size="small"
                          color="primary"
                        />
                      }
                      label={'Replace my secondary insurance'}
                    />
                  )}

                  {JSON.stringify(secondary) === '{}' ? (
                    <FormControlLabel
                      value="4"
                      control={
                        <Radio
                          size="small"
                          color="primary"
                        />
                      }
                      label={'Add a secondary insurance'}
                    />
                  ) : (
                    <FormControlLabel
                      value="5"
                      control={
                        <Radio
                          size="small"
                          color="primary"
                        />
                      }
                      label={'Delete my secondary insurance'}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              xs={12}
              item
            >
              <div className="button_grid">
                <div className="edit_button_container">
                  <Button
                    className="edit_button"
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onClick={handlePrev}
                  >
                    Prev
                  </Button>
                </div>

                <div className="next_button_container">
                  <Button
                    className="next_button"
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={handleNext}
                  >
                    {insOption === '1' || insOption === '3'
                      ? 'Check in'
                      : 'Continue'}
                  </Button>
                </div>
              </div>
            </Grid>
          </>
        ) : (
          <EditInsurance
            primary={primary}
            secondary={secondary}
            // patientId={patientId}
            handleCancel={handleCancel}
            // handleUpdate={handleUpdate}
            handleCheckin={() => handleCheckin()}
            insOption={insOption}
          />
        )}
      </Grid>
    </div>
  );

  return render;
}
export default InsuranceDetails;
