import React from "react";
import Grid from "@material-ui/core/Grid";
import "./style.css";

/**
 * @fileOverview Manges loader
 * @component
 * @author Athul George <athul@trillium-technologies.com>
 * @return {JSXElement}
 * @example
 * return(
 * <Loader/>
 * )
 */
const Loader = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        {/* <img alt="logo" height="100" src={logo} /> */}
        <div className="container">
          <div className="loading">
            <div className="ball"></div>
            <div className="ball"></div>
            <div className="ball"></div>
            <div className="ball"></div>
            <div className="ball"></div>
          </div>
        </div>
      </Grid>
    </div>
  );
};
export default Loader;
