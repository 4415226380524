import { checkin, getCashPatientAmount, getDetails, getInsurance, getPaymentDetails, makeCashPatient, makePayment, saveInsurance, savePersonalDetails, searchPayor } from "./newCheckin.actions";
import { createSlice } from "@reduxjs/toolkit";
let INITIAL_STATE = {
  detailsResponse: {},
  savePersonalRes: {},
  insuranceRes: {},
  saveInsuranceRes: {},
  payorLists: null,
  paymentDetails: {},
  paymentRes: {},
  checkinRes: {},
  cashPatientAmount: {},
  makeCashPatientRes: {},
};
const CheckinSlice = createSlice({
  name: "checkin",
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(getDetails.fulfilled, (state, action) => {
      state.detailsResponse = action.payload;
    });
    builder.addCase(savePersonalDetails.fulfilled, (state, action) => {
      state.savePersonalRes = action.payload;
    });
    builder.addCase(getInsurance.fulfilled, (state, action) => {
      state.insuranceRes = action.payload;
    });
    builder.addCase(checkin.fulfilled, (state, action) => {
      state.checkinRes = action.payload;
    });
    builder.addCase(saveInsurance.fulfilled, (state, action) => {
      state.saveInsuranceRes = action.payload;
    });
    builder.addCase(getPaymentDetails.fulfilled, (state, action) => {
      state.paymentDetails = action.payload;
    });
    builder.addCase(getCashPatientAmount.fulfilled, (state, action) => {
      state.cashPatientAmount = action.payload;
    });
    builder.addCase(searchPayor.fulfilled, (state, action) => {
      state.payorLists = action.payload;
    });
    builder.addCase(makePayment.fulfilled, (state, action) => {
      state.paymentRes = action.payload;
    });
    builder.addCase(makeCashPatient.fulfilled, (state, action) => {
      state.makeCashPatientRes = action.payload;
    });
  },
  reducers: {
    updateData(state) {
        state.saveInsuranceRes = {};
    },
  },
});
export const { updateData } = CheckinSlice.actions;
const { reducer } = CheckinSlice;
export default reducer;
