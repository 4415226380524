import httpCommon from "Redux/http/http-common";
class LoginService {
  userLogin(data) {
    return httpCommon.post(`trillium-patient-service/v1/checkin/${data.dob}?appointmentPinNo=${data.pin}`);
  }
  getLoginData(data)
  {
    return httpCommon.get(`trillium-patient-service/v1/checkin/login/${data}`);
  }
}
export default new LoginService();
