import { createSlice } from "@reduxjs/toolkit";
import { LoginData, login } from "./newLogin.actions";
const INITIAL_STATE = {
  loginResponse: {},
  getLoginData: {},
};

const LoginSlice = createSlice({
  name: "login",
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.loginResponse = action.payload;
    });
    builder.addCase(LoginData.fulfilled, (state, action) => {
      state.getLoginData = action.payload;
    });
  }
});

const { reducer } = LoginSlice;
export default reducer;
