import axios from "axios";
import environment from "environments/environment";
const { baseUrl } = environment;
const token = localStorage.getItem("accessToken") || null;
export default axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    // "application-id": 9,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});
